import React from "react"

import Layout from "../components/layout"
import CommonDedicatedTeam from "../components/dedicated-team/CommonDedicatedTeam"

const DedicatedTeamReact = () => {
  return (
    <Layout pageTitle="Dedicated React Developers">
      {({ width }) => (
        <CommonDedicatedTeam type="React JS/Native" screenWidth={width} />
      )}
    </Layout>
  )
}

export default DedicatedTeamReact
